import React, { useEffect, useState } from "react";
import Table from "./Table";

import { gql, useLazyQuery } from "@apollo/client";
import { Spin } from "antd";

import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface IPhysicians {
  physiciansOverview: {
    _id: string;
    name: string;
    NPI: string;
    contact: string;
    email: string;
    shiftStart: string;
    shiftEnd: string;
    default: number;
  }[];
}

interface iToken {
  login_as: {
    token: string;
  };
}

const DATA_PHYSICIANS = gql`
  query ($physiciansName: String!, $NPI: String!) {
    physiciansOverview(
      physiciansName: $physiciansName
      NPI: $NPI
      sort: {
        _id: 1
        name: 1
        contact: 1
        email: 1
        NPI: 1
        shiftStart: 1
        shiftEnd: 1
        default: 1
      }
    ) {
      _id
      name
      contact
      email
      NPI
      shiftStart
      shiftEnd
      default
    }
  }
`;

const GET_TOKEN = gql`
  query ($email: String!) {
    login_as(email: $email) {
      token
    }
  }
`;

export default function Physicians() {
  const [displayData, setDisplayData] = useState<any>([]);
  const [physiciansName, setPhysiciansName] = useState("");
  const [NPI, setNPI] = useState("");

  const { push } = useHistory();

  const [getPhysicians, { loading, error, data }] = useLazyQuery<IPhysicians>(
    DATA_PHYSICIANS,
    {
      fetchPolicy: "network-only",
      onCompleted: (completeData) => {
        const tempArr = [];
        tempArr.push([
          "Physician ID",
          "Physician name",
          "NPI",
          "Cell phone",
          "Email",
          "Start",
          "End",
          "Care operator",
        ]);

        completeData.physiciansOverview.forEach((value: any) => {
          tempArr.push([
            value._id,
            value.name,
            value.NPI,
            value.contact,
            value.email,
            value.shiftStart,
            value.shiftEnd,
            value.default ? "Yes" : "No",
          ]);
        });

        setDisplayData(tempArr);
      },
    }
  );

  const [getToken] = useLazyQuery<iToken>(GET_TOKEN, {
    fetchPolicy: "network-only",
    onCompleted: (completeData) => {
      console.log("complete token", completeData);
      localStorage.setItem("zilius-token", completeData.login_as.token);
      window.location.reload();
    },
  });

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getPhysicians({
      variables: {
        physiciansName: "",
        NPI: "",
      },
    });
  };

  const getData = () => {
    getPhysicians({
      variables: {
        physiciansName: physiciansName,
        NPI: NPI,
      },
    });
  };

  const clearForm = () => {
    setPhysiciansName("");
    setNPI("");
    getDefaultData();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      getData();
    }
  };

  const buttonClickHandler = (tableRow: any) => {
    console.log("email", tableRow[4]);
    getToken({
      variables: {
        email: tableRow[4],
      },
    });
  };

  return (
    <>
      {loading || data === undefined ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            <FileUpload />
            <CSVLink
              data={displayData}
              filename={`Physicians_${moment().format(
                "MM/DD/YYYY_HH:mm:ss"
              )}.csv`}
              className="export-button"
            >
              Export
            </CSVLink>
          </div>
          <div className="flex-between search-filter-box">
            <div className="flex">
              <div>
                <span className="search-filter-label">
                  Search by physician name
                </span>
                <input
                  type="text"
                  className="search-filter-input"
                  value={physiciansName}
                  onChange={(e) => setPhysiciansName(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div>
                <span className="search-filter-label">Search by NPI</span>
                <input
                  type="text"
                  className="search-filter-input"
                  value={NPI}
                  onChange={(e) => setNPI(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>

            <div className="searchButtons">
              <button
                className="export-button clear"
                onClick={() => {
                  clearForm();
                }}
              >
                Clear
              </button>
              <button
                className="export-button"
                onClick={() => {
                  getData();
                }}
              >
                Search
              </button>
            </div>
          </div>
          <Table
            headers={[
              "Physician ID",
              "Physician name",
              "NPI",
              "Cell phone",
              "Email",
              "Start",
              "End",
              "Care operator",
            ]}
            data={data?.physiciansOverview.map((value) => [
              value._id,
              value.name,
              value.NPI,
              value.contact,
              value.email,
              value.shiftStart,
              value.shiftEnd,
              value.default ? "Yes" : "No",
            ])}
            buttonClick={buttonClickHandler}
          />
        </>
      )}
    </>
  );
}
