import React, { useState, useEffect } from "react";
import { Tag, Row, Col, List, Typography } from "antd";
import moment from "moment";

import { User_Events } from "../../../../lib/graphql/queries/Events/__generated__/User_Events";
import { Viewer } from "../../../../lib/types";

import parsePhoneNumber from "libphonenumber-js";

interface Props {
  userEvents: User_Events["user"]["events"];
  eventsPage: number;
  limit: number;
  setEventsPage: (page: number) => void;
  viewer: Viewer;
}
const { Title } = Typography;

export const UserEvents = ({
  userEvents,
  eventsPage,
  limit,
  setEventsPage,
  viewer,
}: Props) => {
  // The current width of the viewport
  // const width = window.innerWidth;
  // The width below which the mobile view should be rendered
  const breakpoint = 800;

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // Formatting Test
  // Format humanized duration
  moment.relativeTimeThreshold("y", 365);
  moment.relativeTimeThreshold("d", 90);

  const total = userEvents ? userEvents.total : null;
  const result = userEvents ? userEvents.result : null;

  const userEventsListDesktop = userEvents ? (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        position: "bottom",
        current: eventsPage,
        total: total ? total : undefined,
        defaultPageSize: limit,
        hideOnSinglePage: true,
        showLessItems: true,
        onChange: (page: number) => setEventsPage(page),
      }}
      dataSource={result ? result : undefined}
      renderItem={(item) => (
        <List.Item key={item.patient.firstName}>
          <List.Item.Meta
            title={
              <div>
                <Row>
                  <Col xs={16}>
                    <a href={item.eventType}>
                      {item.patient.firstName} {item.patient.lastName}
                    </a>
                  </Col>
                  <Col xs={8}>
                    <div style={{ float: "right", color: "grey" }}>
                      {" ("}
                      {moment
                        .duration(
                          moment().diff(
                            moment(item.eventTime, "YYYY/MM/DD hh:mm A")
                          )
                        )
                        .humanize() + " ago"}
                      {")"}
                    </div>
                  </Col>
                </Row>
              </div>
            }
            description={
              <Row>
                <Tag color="green">
                  {moment(item.eventTime, "YYYY/MM/DD hh:mm A").diff(
                    moment(
                      item.patient.initialDischargeDate,
                      "YYYY/MM/DD hh:mm A"
                    ),
                    "days"
                  )}
                  {"-Day Readmission"}
                </Tag>
                <Tag color="green">{item.eventType}</Tag>
                <Tag color="green">{item.patient.type}</Tag>
              </Row>
            }
          />
          <div className="event-detail__information">
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={8}
              >
                Location
              </Col>
              <Col xs={12}>{item.eventFacility.facilityName}</Col>
            </Row>

            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={8}
              >
                Facility Contact
              </Col>
              <Col xs={12}>
                {item.eventFacility.facilityContact
                  ? parsePhoneNumber(
                      "+1" + item.eventFacility.facilityContact
                    )?.formatNational()
                  : "UNKOWN"}
              </Col>
            </Row>
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={8}
              >
                Patient Contact
              </Col>
              <Col xs={12}>
                {item.patient.contact
                  ? parsePhoneNumber(
                      "+1" + item.patient.contact
                    )?.formatNational()
                  : "UNKOWN"}
              </Col>
            </Row>
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={8}
              >
                Reason for Admission
              </Col>
              <Col xs={12}>{item.admissionDiagnosis}</Col>
            </Row>
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={8}
              >
                Initial Discharge Date
              </Col>
              <Col xs={12}>{item.patient.initialDischargeDate}</Col>
            </Row>
          </div>
        </List.Item>
      )}
    />
  ) : null;

  const userEventsListMobile = userEvents ? (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        position: "bottom",
        current: eventsPage,
        total: total ? total : undefined,
        defaultPageSize: limit,
        hideOnSinglePage: true,
        showLessItems: true,
        onChange: (page: number) => setEventsPage(page),
      }}
      dataSource={result ? result : undefined}
      renderItem={(item) => (
        <List.Item key={item.patient.firstName}>
          <List.Item.Meta
            title={
              <div>
                <Row>
                  <Col xs={12}>
                    <a href={item.eventType}>
                      {item.patient.firstName} {item.patient.lastName}
                    </a>
                  </Col>
                  <Col xs={12}>
                    <div style={{ float: "right", color: "grey" }}>
                      {" ("}
                      {moment
                        .duration(
                          moment().diff(
                            moment(item.eventTime, "YYYY/MM/DD hh:mm A")
                          )
                        )
                        .humanize() + " ago"}
                      {")"}
                    </div>
                  </Col>
                </Row>
              </div>
            }
            description={
              <Row>
                <Tag color="green">
                  {moment(item.eventTime, "YYYY/MM/DD hh:mm A").diff(
                    moment(
                      item.patient.initialDischargeDate,
                      "YYYY/MM/DD hh:mm A"
                    ),
                    "days"
                  )}
                  {"-Day Readmission"}
                </Tag>
                <Tag color="green">{item.eventType}</Tag>
                <Tag color="green">{item.patient.type}</Tag>
              </Row>
            }
          />
          <div className="event-detail__information">
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={14}
              >
                Location
              </Col>
              <Col xs={10}>{item.eventFacility.facilityName}</Col>
            </Row>

            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={14}
              >
                Facility Contact
              </Col>
              <Col xs={10}>{item.eventFacility.facilityContact}</Col>
            </Row>
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={14}
              >
                Patient Contact
              </Col>
              <Col xs={10}>
                {item.patient.contact
                  ? parsePhoneNumber(
                      "+1" + item.patient.contact
                    )?.formatNational()
                  : "UNKOWN"}
              </Col>
            </Row>
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={14}
              >
                Reason for Admission
              </Col>
              <Col xs={10}>{item.admissionDiagnosis}</Col>
            </Row>
            <Row>
              <Col
                className="event-detail__information"
                style={{ color: "#8c8c8c" }}
                xs={14}
              >
                Initial Discharge Date
              </Col>
              <Col xs={10}>{item.patient.initialDischargeDate}</Col>
            </Row>
          </div>
        </List.Item>
      )}
    />
  ) : null;

  // # TODO: Add a Last Updated time passed from DB.
  const userEventsElementDesktop = userEventsListDesktop ? (
    <div className="user-events">
      <h4>Last Updated: </h4>
      <div className="site-layout-content">{userEventsListDesktop}</div>
    </div>
  ) : null;

  // # TODO: Add a Last Updated time passed from DB.
  const userEventsElementMobile = userEventsListMobile ? (
    <div className="user-events">
      {/* <Title level={4} className="user-events__title">
        Readmission Alerts (Mobile)
      </Title>
      {viewer.default ? <ReadmissionAlerts /> : null} */}

      <h4>Last Updated: </h4>
      <div className="site-layout-content">{userEventsListMobile}</div>
    </div>
  ) : null;

  return dimensions.width < breakpoint
    ? userEventsElementMobile
    : userEventsElementDesktop;
};
