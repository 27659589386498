import React, { useState } from "react";
import Table from "./Table";

import { useQuery, gql } from "@apollo/client";
import { Spin } from "antd";

import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import moment from "moment";

const DATA_FACILITY = gql`
  query {
    facilityOverview {
      results {
        id
        facilityName
        facilityCode
        facilityContact
        PONAME
        healthSystemCode
        facilityDirectAddress
      }
    }
  }
`;

export default function Facility() {
  const [displayData, setDisplayData] = useState<any>([]);
  const { data, loading, error } = useQuery(DATA_FACILITY, {
    onCompleted: (completeData) => {
      const tempArr = [];
      tempArr.push([
        "Facility ID",
        "Facility name",
        "Facility PO name",
        "Facility code",
        "Organization code",
        "Practice direct address",
        "Facility phone",
      ]);

      completeData.facilityOverview.results.forEach((value: any) => {
        tempArr.push([
          value.id,
          value.facilityName,
          value.PONAME,
          value.facilityCode,
          value.healthSystemCode,
          value.facilityDirectAddress,
          value.facilityContact,
        ]);
      });

      setDisplayData(tempArr);
    },
  });

  return (
    <>
      {/* <button className="export-button">Export</button> */}

      {loading || !displayData.length ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            <FileUpload />
            <CSVLink
              data={displayData}
              filename={`Facility_${moment().format(
                "MM/DD/YYYY_HH:mm:ss"
              )}.csv`}
              className="export-button"
            >
              Export
            </CSVLink>
          </div>

          <Table
            headers={[
              "Facility ID",
              "Facility name",
              "Facility PO name",
              "Facility code",
              "Organization code",
              "Practice direct address",
              "Facility phone",
            ]}
            data={data.facilityOverview.results.map((value: any) => [
              value.id,
              value.facilityName,
              value.PONAME,
              value.facilityCode,
              value.healthSystemCode,
              value.facilityDirectAddress,
              value.facilityContact,
            ])}
          />
        </>
      )}
    </>
  );
}
