import React, { useEffect, useState } from "react";

import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Spin } from "antd";
import Table from "./Table";
import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import moment from "moment";

interface IPatients {
  patientsOverview: {
    _id: string | null;
    patientName: string | null;
    firstName: string | null;
    lastName: string | null;
    dob: string | null
    NPI: string | null
    visitNumber: string | null
    physician: string | null
    initialDischargeDate: string | null
    physicianUsername: string | null
    physicianId: string
    contact: string | null
    mobileNumber: string | null
    gender: string | null
    dischargeDate: string | null
    address: string | null
    state: string | null
    zip: string | null
    ssn: string | null
    type: string | null
    admitDate: string | null
    city: string
  }[];
}

interface physicians {
  name: string;
}

const DATA_PATIENTS = gql`
  query ($patientName: String!, $NPI: String!) {
    patientsOverview(
      patientsName: $patientName
      NPI: $NPI
      sort: {
        # _id: 1
        patientName: 1
        # NPI: 1
        # dob: 1
        # physician: 1
        # initialDischargeDate: 1
        # visitNumber: 1
      }
    ) {
      _id
      patientName
      firstName
      lastName
      dob
      NPI
      visitNumber
      initialDischargeDate
      physicianUsername
      physicianId
      contact
      mobileNumber
      gender
      dischargeDate
      address
      state
      zip
      ssn
      type
      admitDate
      city
    }
  }
`;

export default function Patients() {
  const [displayData, setDisplayData] = useState<any>([]);
  const [patientName, setPatientName] = useState("");
  const [NPI, setNPI] = useState("");


  const [getPatients, { loading, error, data }] = useLazyQuery<IPatients>(
      DATA_PATIENTS,
      {
        fetchPolicy: "network-only",
        onCompleted: (completeData) => {
          const tempArr = [];
          tempArr.push([
            "Patient ID",
            "Patient name",
            "Patient surname",
            "DOB",
            "SSN",
            "Gender",
            "Admit Date",
            "Initial Discharge date",
            "Physician",
            "NPI",
          ]);

          completeData.patientsOverview.forEach((value) => {
            tempArr.push([
              value._id,
              value.firstName,
              value.lastName,
              value.dob,
              value.ssn?.substr(value.ssn?.length - 4),
              value.gender,
              value.admitDate,
              value.initialDischargeDate,
              value.physicianUsername,
              value.physicianId,
            ]);
          });

          setDisplayData(tempArr);
        },
      }
  );

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getPatients({
      variables: {
        patientName: "",
        NPI: "",
      },
    });
  };

  const getData = () => {
    getPatients({
      variables: {
        patientName: patientName,
        NPI: NPI,
      },
    });
  };

  const clearForm = () => {
    setPatientName("");
    setNPI("");
    getDefaultData();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      getData();
    }
  };

  return (
      <>
        {loading || data === undefined ? (
            <>
              <Spin />
            </>
        ) : (
            <>
              <div className="exportuploadbuttonposition">
                <FileUpload />
                <CSVLink
                    data={displayData}
                    filename={`Patients_${moment().format(
                        "MM/DD/YYYY_HH:mm:ss"
                    )}.csv`}
                    className="export-button"
                >
                  Export
                </CSVLink>
              </div>
              <div className="flex-between search-filter-box">
                <div className="flex">
                  <div>
                <span className="search-filter-label">
                  Search by patient name
                </span>
                    <input
                        type="text"
                        className="search-filter-input"
                        value={patientName}
                        onChange={(e) => setPatientName(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div>
                    <span className="search-filter-label">Search by NPI</span>
                    <input
                        type="text"
                        className="search-filter-input"
                        value={NPI}
                        onChange={(e) => setNPI(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>
                <div className="searchButtons">
                  <button
                      className="export-button clear"
                      onClick={() => {
                        clearForm();
                      }}
                  >
                    Clear
                  </button>
                  <button
                      className="export-button"
                      onClick={() => {
                        getData();
                      }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <Table
                  headers={[
                    "Patient ID",
                    "Patient name",
                    "Visit number",
                    "DOB",
                    "Discharge date",
                    "Physician name",
                    "NPI",
                  ]}
                  data={data?.patientsOverview.map((value) => [
                    value._id,
                    value.patientName,
                    value.visitNumber,
                    moment(value.dob).format("MM/DD/yyyy"),
                    moment(value.initialDischargeDate).format("MM/DD/yyyy"),
                    value.physicianUsername,
                    value.NPI,
                  ])}
              />
            </>
        )}
      </>
  );
}