import React, { useEffect, useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";
import { Spin } from "antd";
import Table from "../DataOverview/Table";
import { CSVLink } from "react-csv";

import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";

interface Hl7Logging {
  hl7Logging: {
    _id: string;
    eventCreatedAt: string;
    hl7ReceivedAt: string;
    messageType: string;
    attendingPhysicianNPI: string;
  }[];
}

enum MessageType {
  A01 = "A01",
  A04 = "A04",
  A11 = "A11",
}

const DATA_HL7LOGGING = gql`
  query ($from: String!, $to: String!, $messageType: MessageType!) {
    hl7Logging(
      eventDatesRange: { from: $from, to: $to }
      messageType: $messageType
      sort: { eventCreatedAt: -1 }
    ) {
      _id
      eventCreatedAt
      hl7ReceivedAt
      messageType
      attendingPhysicianNPI
    }
  }
`;

export default function HL7Logging() {
  const [displayData, setDisplayData] = useState<any>([]);

  const [messageType, setMessageType] = useState<string>(MessageType.A04);
  const [dateRange, setDateRange] = useState(["", ""]);
  const [clearLodaing, setClearLodaing] = useState(false);

  const [getHL7Loggin, { loading, error, data }] = useLazyQuery<Hl7Logging>(
    DATA_HL7LOGGING,

    {
      fetchPolicy: "network-only",
      onCompleted: (completeData) => {
        const tempArr = [];
        tempArr.push([
          "Event date and time",
          "Message recieved on",
          "Message type",
          "Physician NPI",
        ]);

        completeData.hl7Logging.forEach((value) => {
          tempArr.push([
            value.eventCreatedAt,
            value.hl7ReceivedAt,
            value.messageType,
            value.attendingPhysicianNPI,
          ]);
        });

        setDisplayData(tempArr);
        setClearLodaing(false);
      },
    }
  );

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getHL7Loggin({
      variables: {
        from: "",
        to: "",
        messageType: MessageType.A04,
      },
    });
  };

  const getData = () => {
    getHL7Loggin({
      variables: {
        from: dateRange[0],
        to: dateRange[1],
        messageType: messageType,
      },
    });
  };

  const clearForm = () => {
    setDateRange(["", ""]);
    setMessageType(MessageType.A04);
    setClearLodaing(true);
    getDefaultData();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      getData();
    }
  };

  return (
    <div className="container">
      <div className="flex-between ">
        <h4 className="content-title">HL7 logging</h4>
      </div>
      <div className="flex-between search-filter-box">
        <div className="flex">
          <div>
            <span className="search-filter-label">Select event date</span>
            {!clearLodaing ? (
              <DateRangePicker
                format="MM/dd/yyyy"
                onChange={(value) => {
                  console.log(value);
                  if (value) {
                    setDateRange([
                      moment(value[0]).format("YYYY/MM/DD"),
                      moment(value[1]).format("YYYY/MM/DD"),
                    ]);
                  }
                }}
                onClean={(value) => {
                  clearForm();
                }}
              />
            ) : null}
          </div>
          <div className="searchButtons">
            <span className="search-filter-label">Message source</span>
            <select
              name=""
              id=""
              className="search-filter-input"
              style={{ width: "150px" }}
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
              onKeyPress={handleKeyPress}
            >
              <option value="A01">A01</option>
              <option value="A04">A04</option>
              {/* <option value="A11">A11</option> */}
            </select>
          </div>
        </div>

        <div>
          <button
            className="export-button clear"
            onClick={() => {
              clearForm();
            }}
          >
            Clear
          </button>
          <button
            className="export-button"
            onClick={() => {
              getData();
            }}
          >
            Search
          </button>
        </div>
      </div>
      {loading || data === undefined ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            <CSVLink
              data={displayData}
              filename={`HL7Logging_${messageType}_${moment().format(
                "MM/DD/YYYY_HH:mm:ssss"
              )}.csv`}
              className="export-button"
            >
              Export
            </CSVLink>
          </div>

          <Table
            headers={[
              "Event date and time",
              "Message recieved on",
              "Message type",
              "Physician NPI",
            ]}
            data={data?.hl7Logging.map((value) => [
              // value.eventCreatedAt,
              moment
                .tz(value.eventCreatedAt, "America/New_York")
                .format("MM/DD/yyyy HH:mm:ss"),
              // moment(value.eventCreatedAt).format("MM/DD/yyyy HH:mm:ss"),
              // moment(value.hl7ReceivedAt).format("MM/DD/yyyy HH:mm:ss"),
              // value.hl7ReceivedAt,
              moment
                .tz(value.hl7ReceivedAt, "America/New_York")
                .format("MM/DD/yyyy HH:mm:ss"),
              value.messageType,
              value.attendingPhysicianNPI,
            ])}
          />
        </>
      )}
    </div>
  );
}
