import React, { useState } from "react";
import Events from "./Events";
import Facility from "./Facility";
import Patients from "./Patients";
import Physicians from "./Physicians";

export default function DataOverview() {
  const [screen, setScreen] = useState("Facility");

  const renderScreen = () => {
    switch (screen) {
      case "Facility":
        return <Facility />;
      case "Physicians":
        return <Physicians />;
      case "Patients":
        return <Patients />;
      case "Events":
        return <Events />;

      default:
        return <Facility />;
    }
  };
  return (
    <div className="container">
      <div className="flex-between ">
        <h4 className="content-title">Data overview</h4>
      </div>

      <div className="content-menu">
        <ul>
          <li
            className={`${
              screen === "Facility" ? "content-menu-active" : null
            }`}
            onClick={() => setScreen("Facility")}
          >
            Facility
          </li>
          <li
            className={`${
              screen === "Physicians" ? "content-menu-active" : null
            }`}
            onClick={() => setScreen("Physicians")}
          >
            Physicians
          </li>
          <li
            className={`${
              screen === "Patients" ? "content-menu-active" : null
            }`}
            onClick={() => setScreen("Patients")}
          >
            Patients
          </li>
          <li
            className={`${screen === "Events" ? "content-menu-active" : null}`}
            onClick={() => setScreen("Events")}
          >
            Events
          </li>
        </ul>
      </div>
      {renderScreen()}
    </div>
  );
}
