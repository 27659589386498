import React, { useEffect, useState } from "react";

import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Spin } from "antd";
import Table from "./Table";

import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import moment from "moment-timezone";

import DateRangePicker from "rsuite/DateRangePicker";

interface IEvents {
  eventsOverview: {
    event_id: string;
    event_time: string;
    filename: string;
    messageSource: string;
    status: boolean | string;
    date: string;
    error: string | null;
  }[];
}

enum MessageSource {
  PATIENT_UPLOAD = "PATIENT_UPLOAD",
  PHYSICIAN_UPLOAD = "PHYSICIAN_UPLOAD",
  FACILITY_UPLOAD = "FACILITY_UPLOAD",
  ACRS_DELIVERY = "ACRS_DELIVERY",
  ACRS_ATTRIBUTION = "ACRS_ATTRIBUTION",
  HL7_A04 = "HL7_A04",
}

const DATA_EVENTS = gql`
  query ($from: String!, $to: String!, $messageSource: MessageSource!) {
    eventsOverview(
      eventDatesRange: { from: $from, to: $to }
      messageSource: $messageSource
      sort: { event_time: -1 }
    ) {
      event_id
      event_time
      filename
      messageSource
      date
      status
      error
    }
  }
`;

export default function Events() {
  const [displayData, setDisplayData] = useState<any>([]);
  const [messageSource, setMessageSource] = useState<string>(
    MessageSource.PATIENT_UPLOAD
  );
  const [dateRange, setDateRange] = useState<any>(["", ""]);
  const [clearLodaing, setClearLodaing] = useState(false);

  const [getEvents, { loading, error, data }] = useLazyQuery<IEvents>(
    DATA_EVENTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (completeData) => {
        const tempArr = [];
        tempArr.push([
          "Event ID",
          "Event date and time",
          "File name",
          "Message source",
          "Note",
          "Processing result",
        ]);

        completeData.eventsOverview.forEach((value) => {
          tempArr.push([
            value.event_id,
            // moment(value.date).format("MM/DD/yyyy HH:mm:ss"),
            moment
              .tz(value.date, "America/New_York")
              .format("MM/DD/yyyy HH:mm:ss"),
            value.filename,
            value.messageSource,
            value.error,
            value.status === "true" ? "Success" : "Failed",
          ]);
        });

        setDisplayData(tempArr);
        setClearLodaing(false);
      },
    }
  );

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getEvents({
      variables: {
        from: "",
        to: "",
        messageSource: MessageSource.PATIENT_UPLOAD,
      },
    });
  };

  const getData = () => {
    getEvents({
      variables: {
        from: dateRange[0],
        to: dateRange[1],
        messageSource: messageSource,
      },
    });
  };

  const clearForm = () => {
    setDateRange(["", ""]);
    setMessageSource(MessageSource.PATIENT_UPLOAD);
    setClearLodaing(true);
    getDefaultData();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      getData();
    }
  };

  return (
    <>
      <div className="flex-between search-filter-box">
        <div className="flex">
          <div style={{ width: "400px" }}>
            <span className="search-filter-label">Select event date</span>
            {!clearLodaing ? (
              <DateRangePicker
                format="MM/dd/yyyy"
                onChange={(value) => {
                  console.log(value);
                  if (value) {
                    setDateRange([
                      moment(value[0]).format("YYYY/MM/DD"),
                      moment(value[1]).format("YYYY/MM/DD"),
                    ]);
                  }
                }}
                onClean={(value) => {
                  clearForm();
                }}
              />
            ) : null}
          </div>
          <div>
            <span className="search-filter-label">Message source</span>
            <select
              name=""
              id=""
              className="search-filter-input"
              style={{ width: "160px" }}
              value={messageSource}
              onChange={(e) => setMessageSource(e.target.value)}
              onKeyPress={handleKeyPress}
            >
              <option value="PATIENT_UPLOAD">Patient upload</option>
              <option value="PHYSICIAN_UPLOAD">Physician upload</option>
              <option value="FACILITY_UPLOAD">Facility upload</option>
              <option value="ACRS_DELIVERY">ACRS Delivery</option>
              <option value="ACRS_ATTRIBUTION">ACRS Attribution</option>
            </select>
          </div>
        </div>
        <div className="searchButtons">
          <button
            className="export-button clear"
            onClick={() => {
              clearForm();
            }}
          >
            Clear
          </button>
          <button
            className="export-button"
            onClick={() => {
              getData();
            }}
          >
            Search
          </button>
        </div>
      </div>
      {loading || data === undefined ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            <FileUpload />
            <CSVLink
              data={displayData}
              filename={`Events_${messageSource}_${moment().format(
                "MM/DD/YYYY_HH:mm:ss"
              )}.csv`}
              className="export-button"
            >
              Export
            </CSVLink>
          </div>
          <Table
            headers={[
              "Event ID",
              "Event date and time",
              "File name",
              "Message source",
              "Note",
              "Processing result",
            ]}
            data={data?.eventsOverview.map((value) => [
              value.event_id,
              // moment(value.date).format("MM/DD/yyyy HH:mm:ss"),
              moment
                .tz(value.date, "America/New_York")
                .format("MM/DD/yyyy HH:mm:ss"),
              value.filename,
              value.messageSource,
              value.error,
              value.status === "true" ? "Success" : "Failed",
            ])}
          />
        </>
      )}
    </>
  );
}
