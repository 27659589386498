import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { ReactComponent as ArrowRight } from "../../styles/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../styles/ArrowLeft.svg";
import { ReactComponent as ArrowFullRight } from "../../styles/ArrowFullRight.svg";
import { ReactComponent as ArrowFullLeft } from "../../styles/ArrowFullLeft.svg";

interface ITable {
  headers: string[];
  data: any;
  buttonClick?: any;
}

const ITEMS_PER_PAGE = 10;

export default function Table(props: ITable) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + ITEMS_PER_PAGE;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(props.data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.data.length / ITEMS_PER_PAGE));
  }, [itemOffset]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % props.data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setSelectedPage(event.selected);
    setItemOffset(newOffset);
  };

  const handleBeginingPage = () => {
    setItemOffset(0);
    setSelectedPage(0);
  };

  const handleEndPage = () => {
    setItemOffset(((pageCount - 1) * ITEMS_PER_PAGE) % props.data.length);
    setSelectedPage(pageCount - 1);
  };

  // console.log("current items", currentItems);

  return (
    <>
      <table id="content-table">
        <thead>
          <tr>
            {props.headers.map((value, index) => (
              <th key={index}>{value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.length
            ? currentItems.map((value: any, index: number) => (
                <tr key={index}>
                  {value.map((tabelData: string, tableDataIndex: number) => {
                    if (tabelData) {
                      if (tabelData.includes("Error")) {
                        const splitedString = tabelData.split(":");
                        const [showString, tooltipString] = [
                          splitedString.shift(),
                          splitedString.join(":"),
                        ];

                        return (
                          <td key={tableDataIndex} title={tooltipString}>
                            {showString}
                          </td>
                        );
                      }
                    }
                    return (
                      <td
                        key={tableDataIndex}
                        className={`${
                          tabelData === "Success"
                            ? "TableSuccess"
                            : tabelData === "Failed"
                            ? "TableFail"
                            : ""
                        }`}
                      >
                        {tabelData}
                      </td>
                    );
                  })}
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <div className="pagination">
        <div className="paginateButton" onClick={handleBeginingPage}>
          <ArrowFullLeft />
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<ArrowRight />}
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<ArrowLeft />}
          forcePage={selectedPage}
          // renderOnZeroPageCount={null}
        />
        <div className="paginateButton" onClick={handleEndPage}>
          <ArrowFullRight />
        </div>
      </div>
      <div className="content-table-mobile">
        {props.data.map((tableData: any, tableDataIndex: number) => (
          <div key={tableDataIndex} className="TM-table-box">
            {props.headers.map((value, index) =>
              index === 0 ? (
                <div key={value} className="flex-column">
                  <div className="TM-header">{value}</div>
                  <div className="TM-header-content">{tableData[index]}</div>
                </div>
              ) : (
                <div key={value} className="flex-between TM-content">
                  <span className="TM-content-header">{value}</span>
                  <span className="TM-content-data">{tableData[index]}</span>
                </div>
              )
            )}
          </div>
        ))}
      </div>
    </>
  );
}
